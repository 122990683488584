<template>
	<v-app class="fs_main cameras">
		<v-container>
			<v-row>
				<v-col>
					<div class="fs_main_header">
						<h1>{{ projectName }}</h1>
						<!-- <v-btn class="edit"></v-btn> -->
						<span class="split">
							<h2>全体 最新画像一覧</h2>
						</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="fs_photos">
						<template v-for="place in placeList">
							<figure
								v-for="(camera, key2) in place.cameras"
								:key="key2"
							>
								<div class="fs_cam_image">
									<span v-if="camera.photoType != 'V' || !config.enableVideo">
										<a :href="'./place/' + place.id + '?cid=' + camera.id">
											<img
												v-auth-image="
													`https://${config.defaultOriginString}/photo?cid=${camera.id}&name=${camera.createdAt}&type=thumb`
												"
											/>
										</a>
									</span>
									<span v-else>
										<video style="width: 100%; height: 100%;" controls :src="videos[camera.id + '_' + camera.createdAt]" />
									</span>
									<!-- <img src="/img/photo_test.jpg" /> -->
								</div>
								<figcaption>
									<span class="place_name">{{
										place.name
									}}</span>
									<span class="cam_name">
										<a :href="'./place/' + place.id + '?cid=' + camera.id">{{camera.name}}</a>
									</span>
									<span class="latest">最新</span>
									<span
										v-if="camera.photoType == 'M'"
										class="motion"
										>動検知</span
									>
									<span
										v-if="camera.photoType == 'V'"
										class="motion"
										>動画</span
									>
									<span
										v-if="camera.photoType == 'S'"
										class="asap"
										>即時</span
									>
									<span class="date">{{
										convertDate(camera.createdAt)
									}}</span>
									<span v-if="camera.phoneNumber && agentPostfix" class="sms"
										><img src="/img/camera.png" @click.stop="setSmsDialog(camera.phoneNumber)" />
									</span>
								</figcaption>
							</figure>
						</template>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
		<v-dialog v-model="smsDialog">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					SMSコマンド送信
				</v-card-title>
				<v-card-text>
					<div style="width: 90%; margin: 20px; text-align: left;">SMSコマンドをスマートフォンのSMSアプリを使って送信することによりカメラの操作が可能です。次の画面でそのままメッセージを送信してください。</div>
					<v-divider></v-divider>
					<div style="margin: 20px;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*500#`" style="text-decoration: none; color: #000;">
							<v-btn color="error" elevation="2" x-large>即時撮影送信</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						モード変更
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*200#0#`" style="text-decoration: none; color: #000;">
							<v-btn>静止画モード</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*200#1#`" style="text-decoration: none; color: #000;">
							<v-btn>動画モード</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*200#2#`" style="text-decoration: none; color: #000;">
							<v-btn>静止画+動画モード</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						センサー感度変更
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*202#0#`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【高】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*202#1#`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【中】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*202#2#`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【低】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*202#3#`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【OFF】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						タイムラプス設定変更
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<v-select v-model="timelapseItem" :items="timelapseItems" item-text="text" item-value="time" label="タイムラプス時間設定" dense outlined return-object></v-select>
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent(timelapseItem.time)}`" style="text-decoration: none; color: #000;">
							<v-btn>タイムラプス設定</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						画像サイズ変更
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<v-select v-model="imageResoItem" :items="imageResoItems" item-text="text" item-value="size" label="画像サイズ" dense outlined return-object></v-select>
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent(imageResoItem.size)}`" style="text-decoration: none; color: #000;">
							<v-btn>画像サイズ設定</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						送信先メールアドレス追加 / 削除
					</div>
					<div style="margin: 10px; text-align:left; width: 80%;">
						メールアドレス: <v-text-field outlined v-model="email"></v-text-field>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*110#${email}#`" style="text-decoration: none; color: #000;">
							<v-btn>送信先メールアドレス 追加</v-btn>
						</a>
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*111#${email}#`" style="text-decoration: none; color: #000;">
							<v-btn>送信先メールアドレス 削除</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						SMS送信元電話番号追加 / 削除
					</div>
					<div style="margin: 10px; text-align:left; width: 80%;">
						電話番号: <v-text-field outlined v-model="phone"></v-text-field>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*100#${phone}#`" style="text-decoration: none; color: #000;">
							<v-btn>SMS送信元電話番号 追加</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=*101#${phone}#`" style="text-decoration: none; color: #000;">
							<v-btn>SMS送信元電話番号 削除</v-btn>
						</a>
					</div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="smsDialog = false">
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueAuthImage from "vue-auth-image";

Vue.use(VueAuthImage);

export default {
	components: {},
	computed: {},
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.requestPlaces();
	},
	mounted() {
		if (
			navigator.userAgent.indexOf("iPhone") > 0 ||
			navigator.userAgent.indexOf("iPad") > 0 ||
			navigator.userAgent.indexOf("iPod") > 0
		) {
			this.agentPostfix = "&";
		} else if (navigator.userAgent.indexOf("Android") > 0) {
			this.agentPostfix = "?";
		}
	},
	methods: {
		setVideoUrl: function(cid, fileName) {
			axios.get("https://" + this.config.defaultOriginString + "/photo?cid=" + cid + "&name=" + fileName + "&type=video",
				{headers: {
					Authorization: "Bearer " + this.$store.state.auth.data.token,
				}})
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.status == "200") {
						// console.log("Video Success: " + JSON.stringify(res.data));
						this.$set(this.videos, cid + "_" + fileName, res.data);
					} else {
						console.log("Video Fail: " + JSON.stringify(res));
					}
				}).catch((error) => {
					console.log("Video Error: " + error);
				}
			);
		},
		requestPlaces: function () {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/appinfo?tokenType=" +
						this.$store.state.auth.data.tokenType
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res));
						for (
							var i = 0;
							i < res.data.appInfo.places.length;
							i++
						) {
							Vue.set(
								this.placeList,
								i,
								res.data.appInfo.places[i]
							);
							if (res.data.appInfo.places[i].cameras && res.data.appInfo.places[i].cameras.length > 0) {
								for (var j = 0; j < res.data.appInfo.places[i].cameras.length; j++) {
									var item = res.data.appInfo.places[i].cameras[j];

									if (item.photoType == "V") {
										var cid = item.id;
										var fileName = item.createdAt;
										this.setVideoUrl(cid, fileName);
									}
								}
							}
						}
						this.hideLoading();
					} else {
						this.hideLoading();
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		convertDate: function (n) {
			return (
				n.substring(0, 4) +
				"-" +
				n.substring(4, 6) +
				"-" +
				n.substring(6, 8) +
				" " +
				n.substring(8, 10) +
				":" +
				n.substring(10, 12) //+
				// ":" +
				// 				n.substring(12, 14)
			);
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
		setSmsDialog: function(num) {
			this.phoneNumber = num;
			this.smsDialog = true;
		}
	},
	data() {
		return {
			timelapseItem: {text: "24時間", time: "*T#DF#"},
			timelapseItems: [
				{text: "24時間", time: "*T#DF#"},
				{text: "12時間", time: "*T#DE#"},
				{text: "8時間", time: "*T#DD#"},
				{text: "6時間", time: "*T#DC#"},
				{text: "4時間", time: "*T#DB#"},
				{text: "3時間", time: "*T#DA#"},
				{text: "2時間", time: "*T#D9#"},
				{text: "1時間", time: "*T#D8#"},
				{text: "30分", time: "*T#D7#"},
				{text: "10分", time: "*T#D6#"},
				{text: "5分", time: "*T#D5#"},
				{text: "3分", time: "*T#D4#"},
				{text: "1分", time: "*T#D3#"},
				{text: "30秒", time: "*T#D2#"},
				{text: "オフ", time: "*T#D1#"}
			],
			imageResoItem: {text: "300万画素", size: "*C#E1#"},
			imageResoItems: [
				{text: "300万画素", size: "*C#E1#"},
				{text: "500万画素", size: "*C#E2#"},
				{text: "800万画素", size: "*C#E3#"},
				{text: "1000万画素", size: "*C#E4#"},
				{text: "1200万画素", size: "*C#E5#"}
			],
			videos: {},
			placeList: this.$store.state.places.data,
			projectName: this.$store.state.project.data.name,
			agentPostfix: "",
			smsDialog: false,
			phoneNumber: "",
			email: "",
			phone: ""
		};
	},
};
</script>

